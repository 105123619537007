import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import axios from "axios";
import $ from 'jquery';
import bgCurve from "../../images/home/pg-logo-curve.png"
import noImage from "../../images/no-image.jpg"
import stat1 from "../../images/community-name.png"
import stat2 from "../../images/price-last-month.png"
import stat3 from "../../images/price-previous-month.png"
import stat4 from "../../images/price-previous-quarter.png"
import stat5 from "../../images/price-previous-year.png"
import stat6 from "../../images/monthly-change.png"
import stat7 from "../../images/quarterly-change.png"
import stat8 from "../../images/yearly-change.png"

const CommunityStatistics = (props) => {

    const [statistics, setStatistics] = useState(props.Statistics);
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }

    const getitems = async url => {
        try {
            const { data } = await axios.get(url, strapiconfig) // could be from env files  
        
            if (data.status == 1 && data.data?.sales && data.data?.sales?.length > 0 && statistics == null) {
                setStatistics(data.data.sales[0]);
            }
          

        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    useEffect(() => {
        $(document).ready(function () {
            if ($(window).width() > 1024) {
                var setHeight = $(".statistics-section .right-side-img").height();
                $(".statistics-section .right-side-img img").height(setHeight);
            }
            // setTimeout(function () {
            //     if (!$("#statistics-section").length) {
            //         $(".statistics-tab").addClass('d-none');
            //     }
            // }, 1000);
        });
        // getitems(`${process.env.GATSBY_STRAPI_SRC}/api/stb-property-monitor/community-price-trends?category=sales&masterDevelopment=${props.Location}`);
    }, []);
    if (statistics) {
        return (
            <div id="statistics-section" className="statistics-section">
                <span className="bg-curve"><img src={bgCurve} alt="bg-img  statistics - The Pearl Gates" /></span>
                <Container>
                    <Row>
                        <Col xl={12} className="left-side">
                            <div className="animated">
                                <h2>{props.Name} Real Estate Statistics</h2>
                                {/* <p className="sub-text">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu tellus nec leo cursus euismod. Nunc quis felis felis donec ultricies ante.
                                </p> */}
                                <div className="statistics-details">
                                    <div class="single-item">
                                        <div className="stat-icon"><img src={stat1} alt="" /></div>
                                        <div className="stat-name">Community Name</div>
                                        <div>{statistics.CommunityName}</div>
                                    </div>
                                    <div class="single-item">
                                        <div className="stat-icon"><img src={stat2} alt="" /></div>
                                        <div className="stat-name">Price Last Month</div>
                                        <div>AED {statistics.Price_Last_Month}</div>
                                    </div>
                                    <div class="single-item third">
                                        <div className="stat-icon"><img src={stat3} alt="" /></div>
                                        <div className="stat-name">Price Previous Month</div>
                                        <div>AED {statistics.Price_Previous_Month}</div>
                                    </div>
                                    <div class="single-item">
                                        <div className="stat-icon"><img src={stat4} alt="" /></div>
                                        <div className="stat-name">Price Previous Quarter</div>
                                        <div>AED {statistics.Price_Previous_Quarter}</div>
                                    </div>
                                    <div class="single-item">
                                        <div className="stat-icon"><img src={stat5} alt="" /></div>
                                        <div className="stat-name">Price Previous Year</div>
                                        <div>AED {statistics.Price_Previous_Year}</div>
                                    </div>
                                    <div class="single-item third">
                                        <div className="stat-icon"><img src={stat6} alt="" /></div>
                                        <div className="stat-name">Monthly Change</div>
                                        <div>AED {statistics.Monthly_Change}</div>
                                    </div>
                                    <div class="single-item">
                                        <div className="stat-icon"><img src={stat7} alt="" /></div>
                                        <div className="stat-name">Quarterly Change</div>
                                        <div>AED {statistics.Quarterly_Change}</div>
                                    </div>
                                    <div class="single-item">
                                        <div className="stat-icon"><img src={stat8} alt="" /></div>
                                        <div className="stat-name">Yearly Change</div>
                                        <div>AED {statistics.Yearly_Change}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }else{
        return null
    }

}

export default CommunityStatistics