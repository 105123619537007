import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils";
import { Button, Container, Form, Nav, Tab } from 'react-bootstrap';
import Slider from 'react-slick';
// Images
import reviewImg from "../../../images/home/property-rating-img.png"
import bgCurve from "../../../images/home/pg-logo-curve.png"
// styles
import disImg1 from "../../../images/home/discover-img01.jpg"
import disImg2 from "../../../images/home/discover-img02.jpg"
import disImg3 from "../../../images/home/discover-img03.jpg"
import ContactCard from "../ContactCard/ContactCard"
import "./ProjectRating.scss"
// markup
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollContainer from 'react-indiana-drag-scroll'

import { addCommas, stb_sale_link, stb_rent_link, stb_commercial_link } from "../../common/utils"

import axios from "axios"


const ProjectRating = (props) => {

    //console.log("props_Area_Name", props.Area_Name)

    const [propItemsSale, setPropItemsSale] = useState([]);
    const [propItemsRent, setPropItemsRent] = useState([]);

    var stb_link_sale = process.env.GATSBY_STRAPI_SRC + "/api/stb-lists/item/property-for-sale";
    var stb_link_rent = process.env.GATSBY_STRAPI_SRC + "/api/stb-lists/item/property-to-rent";

    const getitems = async (url, status) => {
        //console.log(url)
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // 
            //console.log("PropertySimilar", data)
            if(status === 'sale'){
                setPropItemsSale(data)
            } else{
                setPropItemsRent(data)
            }
            
            //return data;
        } catch (error) {
            // console.error(error)
        }
    }

    // useEffect(() => {
    //     //
    //     let property_sale = getitems(stb_sale_link);
    // }, []);

    useEffect(() => {
        let AreaName = props.Area_Name.replace(" City", "")
        AreaName && getitems(stb_link_sale+'?area='+AreaName, 'sale');
        AreaName && getitems(stb_link_rent+'?area='+AreaName, 'rent');

        //console.log(AreaName)

    }, [props.Area_Name]);

    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        centerMode: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 1199,
                settings: "unslick",
            },
            {
                breakpoint: 99999,
                settings: "unslick",
            },
        ]
    }

   // console.log("propItems", propItemsSale, propItemsRent);

    return (
        <div className={`market-analysis project-analysis ${((propItemsSale && propItemsSale.length > 0) || (propItemsRent && propItemsRent.length > 0)) ? '' : 'd-none'}`}>
            <span className="bg-curve"><img src={bgCurve} alt={`The Pearl Gates`} /></span>
            <Container>
                {/*  <div className="animated"> */}
                {               
                    <div className="discover-range d-md-block">
                        {
                            props.Heading ? (
                                <h2 className="text-center">{props.Heading}</h2>
                            ) : (
                                <h2 className="text-center">{props.Title}</h2>
                            )
                        }
                        
                        {
                            ((propItemsSale && propItemsSale.length > 0) || (propItemsRent && propItemsRent.length > 0)) && 
                            <Tab.Container id="left-tabs-example" defaultActiveKey={propItemsSale?.length > 0 ? "0" : "1"}>
                            <Nav variant="pills">
                                <div className="pills-wrap">
                                     <div className="animated">
                                    <ScrollContainer className="scroll-container">
                                        <>
                                        {
                                            (propItemsSale && propItemsSale.length > 0) && 
                                            <Nav.Item>
                                                <Nav.Link eventKey="0">Properties for Sale</Nav.Link>
                                            </Nav.Item>
                                        }
                                        {
                                            (propItemsRent && propItemsRent.length > 0) && 
                                            <Nav.Item>
                                                <Nav.Link eventKey="1">Properties for Rent</Nav.Link>
                                            </Nav.Item>
                                        }   
                                            
                                        </>
                                        </ScrollContainer>
                                     </div>
                                </div>
                            </Nav>
                            
                             <div className="animated">
                                <Tab.Content>
                                    {
                                        propItemsSale && propItemsSale.length > 0 && 
                                    
                                        <Tab.Pane eventKey="0" className="text-center">
                                            
                                            <Slider {...settings} >
                                                {
                                                    propItemsSale.map((item, index) => {

                                                        var url = "/properties-for-sale/";
                                                        var tag = "SOLD";

                                                        if(item.status === "for sale"){
                                                            url = "/properties-for-sale/"
                                                            tag = "";
                                                        } else if(item.status === "rented"){
                                                            url = "/properties-let/"
                                                            tag = "LET";
                                                        } else if(item.status === "for rent"){
                                                            url = "/properties-for-rent/"
                                                            tag = "";
                                                        } else if(item.status === "sold"){
                                                            url = "/properties-sold/"
                                                            tag = "SOLD";
                                                        }
                                                        let image_all = JSON.parse(item?.images)

                                                        return (
                                                            <ContactCard
                                                                cardImg={image_all.length > 0 && image_all[0]}
                                                                cardTitle={item.title+' in '+item.display_address}
                                                                date={`${addCommas(item.price)} AED`}
                                                                cardTag={tag}
                                                                cardUrl={`${url}${item.slug}-${item.id}`}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Slider>                                        
                                        </Tab.Pane>
                                    }

                                    {
                                        (propItemsRent && propItemsRent.length > 0) && 
                                        <Tab.Pane eventKey="1" className="text-center">

                                            <Slider {...settings} >
                                                {
                                                    propItemsRent.map((item, index) => {

                                                        var url = "/properties-for-sale/";
                                                        var tag = "SOLD";

                                                        if(item.status === "for sale"){
                                                            url = "/properties-for-sale/"
                                                            tag = "";
                                                        } else if(item.status === "rented"){
                                                            url = "/properties-let/"
                                                            tag = "LET";
                                                        } else if(item.status === "for rent"){
                                                            url = "/properties-for-rent/"
                                                            tag = "";
                                                        } else if(item.status === "sold"){
                                                            url = "/properties-sold/"
                                                            tag = "SOLD";
                                                        }

                                                        //console.log("tag==", tag)
                                                        let image_all = JSON.parse(item?.images)

                                                        return (
                                                            <ContactCard
                                                                cardImg={image_all.length > 0 && image_all[0]}
                                                                cardTitle={item.title+' in '+item.display_address}
                                                                date={`${addCommas(item.price)} AED`}
                                                                cardTag={tag}
                                                                cardUrl={`${url}${item.slug}-${item.id}`}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Slider>
       
                                        </Tab.Pane>
                                    }
                                    
                                </Tab.Content>
                             </div>
                        </Tab.Container>
                        }
                        
                    </div>
                }
                {/*  </div> */}
            </Container>
        </div>
    )
}

export default ProjectRating
