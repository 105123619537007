import { Link } from "@StarberryUtils";
import React, {useEffect, useState } from "react"
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useLocation } from "@reach/router";
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery';

// Style
import './ContactInfo.scss';

import { ImageModule } from "../../../modules/Image_Module";
import { removeDataAttributes } from "../../../comQueryStructure";

const ContactInfocommunity = (props) => {
const officeData = removeDataAttributes(props?.Office_Details)

   const location = useLocation();
   const thePath  = location.pathname;
   const pathName = thePath.replace("/living-in-dubai/","");
   const baseName = pathName.split("/");
   /*console.log("baseName: ",baseName);
   console.log("length:- ",baseName.length);*/
   let indexVal = parseInt((baseName.length)-1);
   if(!baseName[indexVal]) {
     indexVal = indexVal-1;
   }
   const actUrl = baseName[indexVal];
   const con_name = actUrl.replaceAll("-"," ");
   let btnName = "";
   if(con_name){
     btnName = con_name.toLowerCase().replace(/\b[a-z]/g, function(letter) {
       return letter.toUpperCase();
     });
   }
    
    return (
        <div id="about-section" className={`contact-info community-info ${props.classNames}`}>
            <Container>
                <Row>
                    <Col xl={5}>
                         <div className="animated">
                            <h2>{props.Title}</h2>
                            {
                                officeData &&
                                <div className="negotiator nego-contact-info d-flex justify-content-between">
                                    <figure>
                                        <ImageModule ImageSrc={officeData.Image} />
                                    </figure>
                                    <div className="info">
                                        <strong className="name d-block">{officeData.Name}</strong>
                                        <div className="contact-nav">
                                            <a href={`tel:${officeData.Direct_Phone}`}>{officeData.Direct_Phone}</a>
                                            <span>|</span>
                                            <Link to={props.ContactLink ? props.ContactLink : "/our-offices/real-estate-agents-in-dubai/"}>Contact Office</Link>
                                        </div>
                                    </div>
                                </div>
                            }
                         </div>
                    </Col>
                    <Col xl={7}>
                         <div className="animated">
                            <p className="desc">
                                {HTMLReactParser(props.Content)} 
                            </p>
                         </div>
                    </Col>
                </Row>
            </Container>
        </div>)
}

export default ContactInfocommunity